import React from "react";
import clients from "./clients";
import Enter from "./Enter";
import styles from "./client.module.css";
import styless from "./enter.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Client() {
  return (
    <div className="container-fluid">
      <div className={`${styles.head}`} id="clients">
        <AnimationOnScroll
          animateIn="animate__bounceInUp"
          animateOnce="true"
          duration="1.5s"
        >
          OUR CLIENTS
        </AnimationOnScroll>
      </div>
      <div className={`${styles.row} row`}>
        {clients.map((pic) => (
          <Enter key={pic.id} img={pic.img} />
        ))}
        <span
          className={`${styless.col} col-lg-2 col-md-3 col-sm-4 col-5`}
          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
        >
          + many more
        </span>
      </div>
    </div>
  );
}

export default Client;
