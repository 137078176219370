import React from 'react';
import Services from "./service";
import Entry from './Entry';
import styles from "./cards.module.css";

function Cards() {
  return (
    <div>
        <div className={`row ${styles.cardbox}`}>
            {Services.map(car =>(
                <Entry
                key={car.id}
                img={car.img}
                name={car.name}
                des1={car.description1}
                />
            ))}
        </div>
    </div>
  )
}

export default Cards;