const Services = [
  {
    id: 1,
    img: "./Images/social-media.png",
    name: "SOCIAL MEDIA MANAGEMENT",
    description1:"Social media management entails building and maintaining a social media presence to engage customers and followers. It involves posting, scheduling, replying to comments and messaging, monitoring metrics, and implementing brand awareness and customer relations initiatives."
  },
  {
    id: 2,
    img: "./Images/ux.png",
    name: "WEBSITE DESIGN",
    description1:"Website creation involves establishing a functional and attractive internet-accessible website. It includes creating the layout and user experience, producing and editing content, and setting up the technological infrastructure to host and maintain the site."
  },
  {
    id: 3,
    img: "./Images/seo.png",
    name: "SEO",
    description1:"SEO optimises a website to rank better in SERPs and get more organic traffic.Keywords in website content, site performance and usability improvements, and high-quality backlinks can achieve this."
  },
  {
    id: 4,
    img: "./Images/digital-marketing.png",
    name: "DIGITAL MARKETING",
    description1:"Digital marketing promotes goods and services via digital channels and technologies. It covers email, social media, content, SEO, and PPC marketing."
  },
  {
    id: 5,
    img: "./Images/Designing.png",
    name: "BRANDING & DESIGNING",
    description1:"Branding creates and maintains a company or product's image. Designing creates brand-supporting logos, websites, packaging, and marketing materials."
  },
  {
    id: 6,
    img: "./Images/videography.png",
    name: "VIDEO & PHOTOGRAPHY",
    description1:"Video and photography use cameras and other recording equipment to capture images. These mediums can be used for advertising, documenting events, storytelling, and preserving memories."
  },
];
export default Services;
