import React from "react";
import "./Navbar.css";
import "animate.css";

function Navbar() {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        "z-index": "4",
        "background-color": "#e8eef5",
      }}
    >
      <nav className="navbar container-fluid navbar-expand-lg navitat">
        <a
          className="navbar-brand"
          style={{ padding: "5px 10px" }}
          href="#home"
        >
          <img
            src="/Images/logo.png"
            alt="Logo"
            className="logo-img animate__animated animate__fadeInDown"
          ></img>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item animate__animated animate__fadeInDown">
              <a className="nav-link" href="#home" aria-current="page">
                HOME
              </a>
            </li>
            <li className="nav-item animate__animated animate__fadeInDown">
              <a className="nav-link" href="#why">
                ABOUT US
              </a>
            </li>
            <li className="nav-item animate__animated animate__fadeInDown">
              <a className="nav-link" href="#serv">
                SERVICES
              </a>
            </li>
            <li className="nav-item animate__animated animate__fadeInDown">
              <a className="nav-link">OUR WORK</a>
            </li>
            <li className="nav-item animate__animated animate__fadeInDown">
              <a className="nav-link" href="#clients">CLIENTS</a>
            </li>
            <li className="nav-item animate__animated animate__fadeInDown">
              <a className="nav-link" href="#cont">
                CONTACT US
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
