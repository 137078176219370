import React from "react";
import styles from "./why.module.css";
import Body from "./Body";
import "animate.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

function Why() {

  return (
    <div id="why">
      <div className={`${styles.container} container-fluid`}>
        <div className={`${styles.body} `} >
          <img src="/Images/Blue Pyramid.png" alt="Design" />
          <AnimationOnScroll animateIn="animate__jackInTheBox" animateOnce="true" duration="1.5s" className={`${styles.head}`}>WHY BLAZEXDIGITAL?</AnimationOnScroll>
        </div>
        <Body />
      </div>
    </div>
  );
}

export default Why;
