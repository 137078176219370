import React from "react";
import Contact from "./Contact";
import Quick from "./Quick";

function Footer() {
  return (
    <div style={{ "background-color": "#152032" }}>
      <Contact />
      <Quick />
      <div
        style={{ backgroundColor: "#1A3143", textAlign: "center", color: "white",padding:"5px 0px" }}
      >
        All Rights Reserved BLAZEXDIGITAL Pvt Ltd. &#169;
      </div>
      <br />
    </div>
  );
}

export default Footer;
