import React from 'react';
import Navbar from "./Navbar";
import Body from './Body';
import Background from './Background';

function Header() {
  return (
    <div id="head" className="header" style={{position:"relative",height:"auto"}}>
            <Navbar />
            <Background />
            <Body />
    </div>
  )
}

export default Header;