import React from 'react';

function Background() {
  return (
    <div Style="filter:brightness(50%)">
        <video width="100%" loop autoPlay muted>
            <source src="/Images/DM.mp4" type="video/mp4"/>
        </video>
    </div>
  )
}

export default Background