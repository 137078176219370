import React from "react";
import styles from "./Services.module.css";
import Cards from "./Cards";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Services() {
  return (
    <div>
      <div
        className={`${styles.box} container-sm container-md conatainer-lg conatainer-xxl`}
        id="serv"
      >
        <AnimationOnScroll
          animateIn="animate__zoomInLeft"
          animateOnce="true"
          duration="18.5s"
          className={`${styles.head}`}
        >
          OUR SERVICES
        </AnimationOnScroll>
        <div className="container">
          <Cards />
        </div>
      </div>
      <br/>
    </div>
  );
}

export default Services;
