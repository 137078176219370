import axios from "axios";
import React from "react";
import "./Form.css";

function Form(props) {
  const handleSubmit = (e) => {
    let name=document.getElementById("name").value;
    let email=document.getElementById("email").value;
    let phone=document.getElementById("phone").value;
    let message=document.getElementById("message").value;
    e.preventDefault(); 
    const data = {
      Name: name,
      Email: email,
      Phone: phone,
      Message: message,
    };
    axios
      .post(
        "https://sheet.best/api/sheets/8b7ecbe2-f59b-4151-80dd-09a11086a5cb",

        data
      )
      ;
    props.close();
    alert("You will be contacted soon");
  };
  return (
    <div>
      <div class="background">
        <div class="container">
          <div class="screen">
            <div class="screen-header">
              <div class="screen-header-left">
                <div class="screen-header-button close"></div>
                <div class="screen-header-button maximize"></div>
                <div class="screen-header-button minimize"></div>
              </div>
              <div class="screen-header-right">
                <div class="screen-header-ellipsis"></div>
                <div class="screen-header-ellipsis"></div>
                <div class="screen-header-ellipsis"></div>
              </div>
            </div>
            <div class="screen-body">
              <div class="screen-body-item left">
                <div class="app-title">
                  <span>CONTACT</span>
                  <span>US</span>
                </div>
                <div class="app-contact">CONTACT INFO : +91 9625306525</div>
              </div>
              <div class="screen-body-item">
              <form onSubmit={handleSubmit}>
                <div class="app-form">
                  <div class="app-form-group">
                    <input
                      type="text"
                      id="name"
                      class="app-form-control"
                      placeholder="NAME"
                      required
                    />
                  </div>
                  <div class="app-form-group">
                    <input
                      type="email"
                      id="email"
                      className="app-form-control"
                      placeholder="EMAIL"
                      required
                    />
                  </div>
                  <div class="app-form-group">
                    <input
                      type="tel"
                      id="phone"
                      class="app-form-control"
                      placeholder="CONTACT NO"
                      minLength="10"
                      maxLength="10"
                      required
                    />
                  </div>
                  <div class="app-form-group message">
                    <input
                      type="text"
                      id="message"
                      class="app-form-control"
                      placeholder="MESSAGE"
                    />
                  </div>
                  <div class="app-form-group buttons">
                    <button
                      class="app-form-button cancel-hover"
                      style={{ marginRight: "20px" }}
                      onClick={props.close}
                    >
                      CANCEL
                    </button>
                    <input type="submit" class="app-form-button submit-hover" value="SUBMIT"></input>  
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;