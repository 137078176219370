import React from "react";
import styles from "./enter.module.css";

function Enter(props) {
  return (
    <div className={`${styles.col} col-lg-2 col-md-3 col-sm-4 col-5`}>
        <img
          className={`${styles.img} `}
          src={`${props.img}`}
          alt="client logo"
        />
    </div>
  );
}

export default Enter;
