import React from "react";
import styles from "./Body.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Body() {
  return (
    <div>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div
              className={`${styles.col} col-lg-6  col-md-12 text-center text-wrap`}
            >
              <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true" duration="1.5s">
                <span style={{fontWeight: 'bold',fontFamily: "'Indie Flower', cursive"}}>Blazexdigital</span> is a pioneer when it comes to building your
                company's online presence. With experience of over 8 dozens
                clients in different domains we know exactly what it takes to
                reach your goals. Our team is highly skilled and in line with
                the latest trends and updates so that you can focus on your
                different sectors of business.
              </AnimationOnScroll>
            </div>
            <div className={`${styles.col} col-lg-6 col-md-12`}>
              <img src="/Images/why.png" className={`${styles.img} `}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
