import React from "react";
import Popup from "reactjs-popup";
import Form from "./Form";
import styles from "./modal.module.css";

function Moda1() {
  return (
    <div>
      <Popup
        trigger={
          <div className={`${styles.button} `}>
            <a href="#" className={`${styles.btn} `}>
              Contact Us
            </a>
          </div>
        }
        modal
      >
        {(close) => (
          <div>
            <Form close={close} />
          </div>
        )}
      </Popup>
    </div>
  );
}
export default Moda1;
