import React from "react";
import styles from "./quick.module.css";

function Quick() {
  return (
    <div>
      <div className={`${styles.container} container text-center`}>
        <div className={`${styles.row} row`}>
          <div className={`${styles.col} col col-xl-2 col-md-4 col-5`}>
            <h5 className={`${styles.head}`}>QUICK LINKS</h5>
            <div className={`${styles.body}`}>
              <div>Home</div>
              <div>About Us</div>
              <div>Our Services</div>
              <div>Our Work</div>
            </div>
          </div>
          <div className={`${styles.col} col col-xl-2 col-md-4 col-5`}>
            <h5 className={`${styles.head}`}>SMM</h5>
            <div className={`${styles.body}`}>
              <div>Facebook Management</div>
              <div>Instagram Management</div>
              <div>YouTube Management</div>
            </div>
          </div>
          <div className={`${styles.col} col col-xl-2 col-md-4 col-5`}>
            <h5 className={`${styles.head}`}>BRANDING & DESIGNING</h5>
            <div className={`${styles.body}`}>
              <div>Logo Design</div>
              <div>Marketing Collateral Design</div>
              <div>Brochure Design</div>
              <div>Catalogue Desgin</div>
              <div>Social media creatives</div>
            </div>
          </div>
          <div className={`${styles.col} col col-xl-2 col-md-4 col-5`}>
            <h5 className={`${styles.head}`}>DIGITAL MARKETING</h5>
            <div className={`${styles.body}`}>
              <div>Google Adwords/SEM</div>
              <div>Facebook Ads</div>
              <div>Instagram Ads</div>
              <div>SEO</div>
              <div>SMO</div>
              <div>Email Marketing</div>
              <div>App Marketing</div>
            </div>
          </div>
          <div className={`${styles.col} col col-xl-2 col-md-4 col-5`}>
            <h5 className={`${styles.head}`}>VIDEO & PHOTOGRAPHY</h5>
            <div className={`${styles.body}`}>
              <div>Ecommerce Product Photography</div>
              <div>Corporate Videos</div>
              <div>Fashion Videos</div>
              <div>Music Videos</div>
              <div>Training Videos</div>
              <div>YouTube Videos</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quick;
