import React from "react";
import styles from "./contact.module.css";
import { GrMail } from "react-icons/gr";
import { BsFillTelephoneFill } from "react-icons/bs";

function Contact() {
  return (
    <div id="cont">
      <br />
      <div className="container body">
        <h1 className={`${styles.head} `}>LET'S GET IN TOUCH</h1>
        <p className={`${styles.para}`}>Do you want to increase your business or plan to market your business on social media?</p>
        <br/>
        <div className={`${styles.text} row`}>
          <div className="col-sm-6">
            <BsFillTelephoneFill /> : +91-9625306525
          </div>
          <div className="col-sm-6">
            <GrMail /> : contact@blazexdigital.com{" "}
          </div>
        </div>
      </div>
      <div className={`${styles.container}`}></div>
    </div>
  );
}

export default Contact;
