// import React from "react";
// import styles from "./entry.module.css";
// import { AnimationOnScroll } from "react-animation-on-scroll";

// function Entry(props) {
//   return (
//     <div className={`${styles.col} col-lg-3 col-5 `}>
//       <AnimationOnScroll
//         animateIn="animate__backInUp"
//         animateOnce="true"
//         duration="1s"
//       >
//         <div className={`${styles.card}  `}>
//             <img
//               src={`${props.img}`}
//               className={`${styles.img} card-img-top`}
//               alt="..."
//             />
//           <div className={`${styles.card_body} card-body`}>{props.name}</div>
//         </div>

//       </AnimationOnScroll>
//     </div>
//   );
// }

// export default Entry;

import React from "react";
import styles from "./entry.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Entry(props) {
  return (
    <div className={`${styles.col} col-lg-3 col-5 `}>
      <AnimationOnScroll
        animateIn="animate__backInUp"
        animateOnce="true"
        duration="1s"
      >
        <div className={`${styles.flip_card}`}>
          <div className={`${styles.flip_card_inner}`}>
            <div className={`${styles.flip_card_front}`}>
              <div className={`${styles.img_body} `}>
                <img
                  src={`${props.img}`}
                  className={`${styles.img} card-img-top`}
                  alt="..."
                />
                </div>
                <div className={`${styles.card_body} card-body`}>
                  {props.name}
                </div>
            </div>
            <div className={`${styles.flip_card_back}`}>
              <p className={`${styles.text_body}`}>{props.des1}</p>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}

export default Entry;
