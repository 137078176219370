import React from "react";
import styles from "./Body.module.css";
import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import "animate.css";
import Typewriter from "typewriter-effect";

function Body() {
  return (
    <div id="home" className={`${styles.container} container-fluid `}>
      <div
        className={`${styles.body} col-8 animate__animated animate__flipInX `}
      >
        <br />

        <div className={`${styles.typewriter}`}>
          <div style={{ color: "#14dcaf" }}>One stop solution</div>
          <div style={{ color: "#14dcaf" }}>for your</div>

          <Typewriter
            options={{
              strings: ["Marketing", "Video Production", "Website Development","SEO","Photography Services"],
              autoStart: true,
              loop: true,
            }}
          />
        </div>

        <div className={`${styles.heading3}`}>
          Supercharge Your Bussiness with Us
        </div>
        <div className={`${styles.iconbody}`}>
          <a
            className={`${styles.iconbox}`}
            href="https://www.instagram.com/blazexdigital/"
            target="_blank"
          >
            <BsInstagram className={`${styles.icon} `} />
          </a>
          <a
            className={`${styles.iconbox}`}
            href="https://www.facebook.com/blazexdigital"
            target="_blank"
          >
            <BsFacebook className={`${styles.icon} `} />
          </a>
          <a
            className={`${styles.iconbox}`}
            href="https://wa.me/919625306525"
            target="_blank"
          >
            <BsWhatsapp className={`${styles.icon} `} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Body;
