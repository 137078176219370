import React from "react";
import Header from "./components/Header/Header";
import Why from "./components/Why/Why";
import Services from "./components/Serv/Services";
import Footer from "./components/Footer/Footer";
import Client from "./components/Clien/Client.jsx";
import Moda1 from "./components/ContactForm/Moda1";

function App() {
  return (
    <div>
      <Header /> 
      <Moda1 />
      <Why />
      <Services />
      <div style={{height:"3rem" ,backgroundColor:"#556f99",boxShadow:"inset 0 0 10px 5px #152032"}}> </div>
      <Client />
      <Footer />   
    </div>
  );
}

export default App;
